$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px,
  xxl: 1610px
);

@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.scss';

body {
  height: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  font-family: 'Switzer', sans-serif;
}

a {
  color: #404040;
}

.desktop-navigation {
  width: 100%;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  z-index: 10;

  @media only screen and (min-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #212529;
    width: 100%;

    @media only screen and (min-width: 768px) {
      background-color: transparent;
      flex-direction: row;
      justify-content: flex-end;

      height: 100%;
      width: auto;
    }

    &--item {
      line-height: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.14);
      width: 90%;
      text-align: left;
      cursor: pointer;

      a {
        color: $light-text-color;
      }

      @media only screen and (min-width: 768px) {
        margin-left: 2.3rem;
        margin-right: 0;
        border-bottom: 0;
        width: auto;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .nav-item-horizontal {
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    margin-bottom: 1rem;

    .active {
      border-color: rgba(0, 0, 0, .5);
    }
  }
}

footer {
  z-index: 20;
  box-shadow: 1px -9px 32px -24px rgba(66, 68, 90, 1);
  color: #404040;
  line-height: 30px;

  a {
    text-decoration: none;
    font-style: normal;
    color: #404040;
  }

  @include footer_headers;

  h6 {
    text-transform: uppercase;
  }

  .partners {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;

    h4 {
      text-transform: uppercase;
      color: #888;
      font-size: 12px;
      line-height: 28px;
      letter-spacing: 0.5em;
      display: table;
      margin: -14px auto 0px auto;
      padding: 0 25px;
      background: #fff;
    }
  }

}

.copyrights {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #454545;
  font-size: 14px;
}

.btn-grayed {
  color: $light-text-color;
  background-color: rgba(255, 255, 255, 0.64);
  border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.main-congress {
  @include section_buttons;

  .sections {
    margin: 1.5rem 0;
  }
}

.contests {
  &--description {
    color: #5B5B5B;
    max-width: 90%;

  }

  &--lead {
    color: #000;
  }
}


.contact {
  @include footer_headers;

  a {
    color: #092E68;

    &:hover {
      text-decoration: underline;
    }
  }

  .sector {
    border-bottom: 1px solid #CED4DA;

    @media only screen and (min-width: 768px) {
      border-bottom: 0;
    }
  }

  &--faq {
    .accordion {

      .card:first-child {
        display: none;
      }

      .btn-link {
        color: #092E68;
      }

      .card-header {
        background-color: transparent;
        border-bottom: 1px solid #CED4DA;
        box-shadow: none;
      }
    }
  }
}

.watch-now {
  position: relative;

  &--appointments {
    color: $dark-color;
    background-color: $light-color;
    position: absolute;
    width: 40rem;
    transform: translate(-40%, 0);
    border-radius: 6px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    margin-top: 12px;

    li {
      line-height: 140%;
    }

    .live {

      font-size: 1rem;
      font-weight: normal;
    }

    .dark {
      filter: invert(100%) brightness(200%) contrast(100%);
    }
  }
}

.form-group {
  .invalid {
    margin-top: 0.3rem;
    color: #fa3f0f;
  }
}

.text-teal {
  color: $teal-color;
}

.form-check-label {
  font-size: 90%
}

@media only screen and (max-width: 500px) {
  .chat {
    overflow: scroll;
  }
}

.magwet-logo {
  width: 150px;
}

.promo-wrapper {
  background-color: #f7f7f7;

  &.shadow-inset {
    box-shadow: inset 0px 2px 23px -11px rgba(66, 68, 90, 1);
  }
}

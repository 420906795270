@import './variables';

.appointment {
  &--title {
    line-height: 1.1em;
    min-height: 2.2em;
    overflow: hidden;
  }

  &--embed-codes-wrapper {
    position: relative;
  }
}

.appointments {
  background-color: $light-color;

  .congress-days {
    background-color: #F1F5FB;
    border-radius: 3px;

    @include section_buttons;

    .sections {
      border-bottom: none;
      margin: 0;

      &--item {
        border-bottom: none;

        &.active, &:hover {
          border-bottom: none;
        }
      }
    }

    &--item {
      color: #333;
      cursor: pointer;

      &--date {
        font-size: 90%;
      }

      &.active {
        color: #000;
        border-bottom: 2px solid #455B7B;

        .congress-days--item--date {
          color: $light-grey-color;
        }
      }
    }

    .workshop-menu-item {
      padding-left: 1rem;
      border-left: 1px solid $light-grey-color;
    }
  }

  &--agenda {
    font-size: 18px;
  }

  &--agenda--badge {
    background-color: #455B7B;
    padding: .1rem .5rem;
    border-radius: 0.6rem;
    color: #fff;
    font-size: 80%;
    margin-left: .5rem;

    &:empty {
      display: none
    }
  }

  &--appointment-groups {
    dt {
      color: $light-grey-color;
    }

    dd {
      color: #249094;
    }

    &--link {
      color: $teal-color;
      text-decoration: none;
    }
  }
}

.agenda {
  h4 {
    border-bottom: 1px solid #E9ECEF;
  }

  &--appointment {
    &:nth-of-type(even) {
      background-color: #F1F3F5;
    }

    &.active {
      font-weight: bold;
      background-color: $blue-100;
    }

    &--speaker {
      font-size: 80%;
      font-weight: normal;
    }
  }
}

.current-workshops {
  .workshop--btn {
    width: 10rem;
  }
}

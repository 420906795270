@import './variables';

.sponsor {
  &--icon {
    width: 4rem;
    height: auto;
  }

  &--view {
    width: 90%;
    min-height: 520px;

    form {
      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          color: #f4f4f4;
        }
      }
    }
  }

  &--logo-wrapper {
    background-color: $light-color;
    border: 1px solid #E9ECEF;
    border-radius: 1.5rem;
    padding: 5px;
    margin-right: auto;
    margin-left: auto;
    width: 40%;

    @media only screen and (min-width: 992px) {
      width: 220px;
      margin-left: 0;
    }
  }

  &--logo-img {
    width: 100%;
    border-radius: 1.5rem;
  }

  &--navigation {
    font-size: 90%;

    li {
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }
  }

  &--info {
    background-color: $light-color;
  }

  &--links {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 1rem;
    width: 90%;
    min-height: 8rem;
    @media only screen and (min-width: 992px) {
      width: 30%;
    }
  }

  &--attachment {
    .sponsor--icon {
      width: 5rem;
    }

    .attachment--thumbnail {
      max-width: 6rem;
    }

    @media only screen and (min-width: 992px) {
      &.one {
        .sponsor--icon {
          width: 10rem;
        }

        .attachment--thumbnail {
          max-width: 15rem;
        }
      }

      &.two {
        width: 45%;

        .sponsor--icon {
          width: 10rem;
        }

        .attachment--thumbnail {
          max-width: 15rem;
        }
      }
      &.more {
        width: 45%;
      }
    }

    a {
      color: $white;
      font-weight: bold;
      display: block;
    }

    small {
      color: $gray-200;
    }
  }

  &--attachments {
    justify-content: left;

    @media only screen and (min-width: 992px) {
      &.one, &.two {
        justify-content: center;
      }
    }
  }

  &--rank-name {
    height: 5rem;
    border-left: 1px solid #E9ECEF;
    padding: 1.8rem 0 0 2rem;
  }

  &--left-arrow, &--right-arrow {
    cursor: pointer;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(40%);
    background-position: center;
    background-size: cover;
    z-index: -1;
  }

  @media only screen and (min-width: 992px) {
    .calendar-widget {
      margin-top: -60px;
    }

    &--description-wrapper {
      position: relative;

      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        content: "\f077";
        color: rgba(255,255,255, 0.5);
        position: absolute;
        top: -10px;
        right: -1px;
      }

      &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        content: "\f078";
        color: rgba(255,255,255, 0.5);
        position: absolute;
        bottom: -10px;
        right: -1px;
      }
    }
  }
}

@import './variables';

$black: #252525;
$white: #fff;
$grey: #212529;
$primary-color: $grey;
$nav-width: 100%;
$nav-padding: 0.875em 1em;
$nav-background-color: $white;
$nav-level-border-color: $primary-color;

.nav-top {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 101;
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  background-color: $primary-color;

  .hamburger {
    margin-left: auto;
    color: $white;
    cursor: pointer;
  }
}

.nav-drill {
  margin-top: 50px;
  transform: translateX(100%);
}

.nav-is-toggled {
  .nav-drill {
    transform: translateX(0);
  }

  &::after {
    opacity: 1;
    visibility: visible;
  }
}

.nav {
  &-drill {
    display: flex;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    width: $nav-width;
    height: 100vh;
    background-color: $grey;
    color: $white;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transition: 0.45s;
    margin-top: 0;
    padding-top: 5rem;
  }

  &-items {
    flex: 0 0 100%;
    padding: 0;
  }

  &-item {
    list-style: none;
    padding: 0;
    margin-left: 1rem;
    margin-right: 1rem;

    &:not(:last-child) {
      border-bottom: solid 1px rgba(255, 255, 255, .14);
    }

    &.event {
      padding: 0.875em 1em;
      width: 93%;

      .time {
        color: rgba(255, 255, 255, .6)
      }
    }

    &-back {
      border-bottom: 0;
      margin: 5rem 1.5rem 0 1rem;
    }

    &-horizontal {
      padding: 0 .5rem;
      border-bottom: solid 1px rgba(255, 255, 255, .14);

      .active {
        border-bottom: 3px solid #fff;
        font-weight: bold;
      }
    }

    .right-arrow {
      margin-top: .8rem;
    }
  }

  &-link-block {
    display: block;
    width: 100%;
    padding: $nav-padding;
    color: $white;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 300;
  }

  &-expand {
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      background-color: $grey;
      transition: 0.3s;
      visibility: hidden;

      .nav-item {
        display: block;
        width: 100%;

        &:not(:last-child) {
        }

        &.event {
          width: 93%;
          border-bottom: 0;
        }
      }

      .nav-back-link {
        display: flex;
        align-items: center;
        color: $white;
      }
    }

    &-link {
      display: flex;
      justify-content: space-between;

      &::after {
        padding-top: .8rem;
        content: url('../images/icons/arrow-right.svg');
        flex: 0 1 auto;
      }
    }

    &.active {
      > .nav-expand-content {
        transform: translateX(0);
        visibility: visible;
      }
    }
  }
}

@mixin transition($params) {
  -webkit-transition: $params;
  -moz-transition: $params;
  -khtml-transition: $params;
  -o-transition: $params;
  transition: $params;
}

@mixin rotate($params) {
  -webkit-transform: rotate($params);
  -moz-transform: rotate($params);
  -khtml-transform: rotate($params);
  -o-transform: rotate($params);
  transform: rotate($params);
}

#ham {
  border-top: 2px solid $white;
  height: 25px;
  width: 30px;
  box-sizing: border-box;
  position: absolute;
  z-index: 150;
  right: 20px;
  top: 15px;
  cursor: pointer;
  @include transition(all 0.3s ease-in);

  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    background: $white;
    top: 10px;
    @include transition(all 0.3s ease-in);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    background: $white;
    bottom: 0;
    @include transition(all 0.3s ease-in);
  }
}

.light-bg {
  #ham {
    border-top: 2px solid $black;
    z-index: 150;

    &:before {
      background: $black;
    }

    &:after {
      background: $black;
    }
  }
}

.active {
  #ham {
    border-color: transparent;

    &:before {
      @include rotate(45deg);
      width: 33px;
      left: -2px;
      background: $white;
    }

    &:after {
      @include rotate(135deg);
      bottom: 11px;
      width: 33px;
      left: -2px;
      background: $white;
    }
  }
}


.desktop-navigation {
  width: 100%;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  z-index: 10;

  @media only screen and (min-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #212529;
    width: 100%;

    @media only screen and (min-width: 768px) {
      background-color: transparent;
      flex-direction: row;
      justify-content: flex-end;
      height: 100%;
      width: auto;
    }

    &--item {
      line-height: 4.2rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.14);
      width: 90%;
      text-align: left;
      cursor: pointer;

      @media only screen and (min-width: 768px) {
        margin-left: 2.3rem;
        margin-right: 0;
        border-bottom: 0;
        width: auto;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .nav-item-horizontal {
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    margin-bottom: 1rem;

    .active {
      border-color: rgba(0, 0, 0, .5);
    }
  }
}

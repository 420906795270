$light-color: #fff;
$dark-grey: #555;
$dark-color: #000;
$header-color: #140E38;
$light-text-color: $light-color;
$footer-color: #fff;
$navy-dark-color: $header-color;
$body-background-color: #FFC400;
$light-border-color: #4A446A;
$dark-blue: #326fac;
$light-blue: #345E92;
$light-grey-color: #868E96;
$teal-color: #249094;

// --------- new color scheme naming
$white: #FFF;
$black: #000;

$gray-50: #F2F2F2;
$gray-100: #E4E4E4;
$gray-200: #AEAEAE;
$gray-600: #646464;
$gray-800: #242424;
$gray-900: #1A1A1A;


$blue-100: #EEF3FF;
$blue-200: #f7f9ff;
$blue-400: #0079C2;
$blue-500: #2C5DE5;

$dark-blue-500: #455B7B;
$dark-blue-600: #092E68;

$gold-500: #E1C42D;

$teal-500: #00AD8E;

$orange-100: #FEF7F6;
$orange-200: #FFE0D7;
$orange-500: #F46A3E;


@mixin title_font {
  font-family: "Inter", "Open Sans", sans-serif;
}

@mixin gradient($from-color, $to-color) {
  background-color: mix($from-color, $to-color); /* Fallback */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from-color), to($to-color));
  background-image: -webkit-linear-gradient(left, $from-color, $to-color);
  background-image: -moz-linear-gradient(left, $from-color, $to-color);
  background-image: -ms-linear-gradient(left, $from-color, $to-color);
  background-image: -o-linear-gradient(left, $from-color, $to-color);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$from-color}', EndColorStr='#{$to-color}')";
}

@mixin title_divider {
  height: .3rem;
  width: 10rem;
  border-radius: .5rem;
  @include gradient(#307ec3, #345F95);
}

@mixin footer_headers {
  h5 {
    @include title_font;
    font-size: 1.3rem;
    display: inline-block;
    padding: 0 3rem .3rem 0;
  }

  h6 {
    color: $light-grey-color;
  }
}

@mixin section_buttons {
  .sections {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    transition: all;

    &--item {
      display: inline-block;
      margin-right: 1rem;
      transition: all 0.2s ease-in;
      padding-bottom: 0.5rem;

      &.active, &:hover {
        border-bottom: 3px solid $light-color;
      }
    }

    &--link {
      &:hover {
        color: $light-color;
      }

      color: $light-color;
    }
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1240px;
  }
}

@import '~bootstrap';
@import './variables';
@import './navigation.scss';

header {
  color: $light-text-color;

  @include media-breakpoint-down(sm) {
    min-height: 66px;
  }

  .logo {
    &-link {
      z-index: 150;
      line-height: 1em;
    }

    &-img {
      height: 24px;
    }
  }

  &.light-bg {
    background-color: $light-color;
  }

  a {
    color: #000;
    text-decoration: none;

    &:hover, &:active {
      color: #092E68;
    }
  }

  &.light-bg {
    color: #000;

    .navigation {

      &--item {
        a {
          color: #000;

          &:hover, &:active {
            color: #092E68;
          }
        }
      }
    }

    .ico-menu {
      color: $dark-color;
    }
  }

  .ico-menu {
    display: flex;
    flex-direction: column;
    text-align: center;

    .fas {
      font-size: 1.5rem;
    }
  }
}
